<template>
  <cw-page
    icon="check"
    class="instalment-loan__complete"
  >
    <template #hero-title>
      <translate>
        Thank you for choosing our service!
      </translate>
    </template>

    <template #default>
      <cw-form-loader
        v-if="loadingData"
        :animate="false"
        style="height: 403px;"
      />

      <v-row
        v-else
        justify="center"
        class="content__wrapper"
      >
        <v-col
          cols="12"
          md="6"
        >
          <p class="mb-6">
            <translate>
              Thank you for choosing our service and welcome to Saldo!
            </translate>
          </p>
          <div class="cw-application__table">
            <table style="width: 100%;">
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td class="text-left">
                    {{ item.name }}
                  </td>
                  <td class="text-right font-weight-medium">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p
            id="instalment-loan__complete__invoicing-method"
            class="mt-6 mb-0"
          >
            <translate v-if="initialData.invoicingMethod === 'post'">
              We will send you invoices 14 days before the due date by post.
            </translate>
            <translate v-if="initialData.invoicingMethod === 'email'">
              We will send you invoices 14 days before the due date by email.
            </translate>
            <translate>
              You can manage your loans easily at MySaldo.
              In MySaldo you can see your available additional services such as the
              Saldo Safe loan insurance that insures your payment ability in case of
              circumstances such as unemployment.
            </translate>
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <cw-review :data="initialData"/>
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-spacer/>
      <v-btn
        v-if="product.ownPagesUrl"
        id="submit"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          toMySaldo();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Proceed to MySaldo</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwCompleteInstalmentLoan',

  components: {
    'cw-review': () => import('@shared/components/Review'),
  },

  props: {
    timeout: {
      type: Number,
      default: 10000,
    },
  },

  data: () => ({
    loadingData: false,
    redirect: null,
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
      offer: 'application/getSelectedOffer',
      product: 'application/getProduct',
    }),

    brand() {
      if (!this.product || !this.product.brand) return '';
      return this.product.brand.toLowerCase();
    },

    items() {
      return [{
        name: this.$gettext('Loan amount'),
        value: this.$filters.currency(this.offer.loanLimit, { locale: this.locale }),
      }, {
        name: this.$gettext('Will be paid'),
        value: this.paymentScheduleDescription,
      }, {
        name: this.$gettext('Account number'),
        value: this.initialData.iban,
      }];
    },

    paymentScheduleDescription() {
      const { nightWithdrawalTime } = this.initialData;

      if (nightWithdrawalTime) {
        const formattedTime = this.$filters.date(nightWithdrawalTime, {
          type: 'time',
          locale: this.locale,
        });

        return this.$gettextInterpolate(
          this.$gettext("at the earliest %{ time } o'clock."),
          { time: formattedTime },
        );
      }

      return this.$gettext('as quickly as possible');
    },
  },

  mounted() {
    this.automaticRedirectToMySaldo();
  },

  beforeDestroy() {
    clearTimeout(this.redirect);
  },

  methods: {
    ...mapActions({
      transferSession: 'application/transferSession',
    }),

    automaticRedirectToMySaldo() {
      this.redirect = setTimeout(async () => {
        await this.toMySaldo();
      }, this.timeout);
    },

    async toMySaldo() {
      const ownPagesUrl = (this.product.ownPagesUrl || '').replace(/\/+$/, '');
      const { tokenId } = (await this.transferSession()).data;
      const url = tokenId !== undefined
        ? `${ownPagesUrl}/app/fromNetApp/${tokenId}`
        : ownPagesUrl;
      window.location = url;
    },
  },
};
</script>

<style lang="scss">
  .cw-application__table--complete {
    max-width: 600px;
  }
</style>

<style lang="scss" scoped>
  .instalment-loan__complete .cw-form-loader {
    position: relative !important;
  }
</style>
